import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as PaginationAnt } from 'antd';
import { getString } from '@nackle/intl-tools';
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight
} from '@nackle/origami-icons';
import { Button } from '../../exports';

/*  NOTE: when this component is updated, if elements/components are changed/added/removed, the same changes will need to be made to the `pagination` config in the List component as we needed to duplicate some of this there since you can't pass a whole component in. However if the changes are less/styling only they do not need to be duplicated. */

const JumpNext = () => (
    <a className='ant-pagination-item-link'>
        <div className='ant-pagination-item-container'>
            <span
                role='img'
                aria-label='double-right'
                className='anticon anticon-double-right ant-pagination-item-link-icon'
            >
                <ChevronDoubleRight />
            </span>
            <span className='ant-pagination-item-ellipsis'>•••</span>
        </div>
    </a>
);

const JumpPrev = () => (
    <a className='ant-pagination-item-link'>
        <div className='ant-pagination-item-container'>
            <span
                role='img'
                aria-label='double-left'
                className='anticon anticon-double-left ant-pagination-item-link-icon'
            >
                <ChevronDoubleLeft />
            </span>
            <span className='ant-pagination-item-ellipsis'>•••</span>
        </div>
    </a>
);

const Pagination = ( { showSizeChanger, ...props } ) => {
    const itemRender = ( _, type, originalElement ) => {
        if ( type === 'prev' ) {
            return <Button
                type='link'
                shape='circle'
                aria-label={ getString( 'origami.pagination.previous' ) }
                className='ant-pagination-chevron'
                icon={ <ChevronLeft /> }
            />;
        } else if ( type === 'next' ) {
            return <Button
                type='link'
                shape='circle'
                aria-label={ getString( 'origami.pagination.next' ) }
                className='ant-pagination-chevron'
                icon={ <ChevronRight /> }
            />;
        } else if ( type === 'jump-prev' ) {
            return <JumpPrev />;
        } else if ( type === 'jump-next' ) {
            return <JumpNext />;
        } else {
            return originalElement;
        }
    };

    return (
        <PaginationAnt
            { ...props }
            showSizeChanger={ showSizeChanger ? showSizeChanger : false }
            itemRender={ itemRender }
            className={ props.size === 'large' ? props.className + ' ant-pagination-large' : props.className }
        />
    );
};
Pagination.propTypes = {
    /** optional class name */
    className: PropTypes.string,
    /** show page size changer */
    showSizeChanger: PropTypes.bool
};
export { Pagination };