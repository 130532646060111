import React from 'react';

export default ( { className, fill, ...props } ) => (
    <svg width="24" height="24" viewBox="0 0 24 24" className={ className } { ...props } fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.67 13.68C7.77 15.65 9.8 16.87 12 16.87C14.2 16.87 16.24 15.65 17.33 13.68L16.02 12.95C15.19 14.44 13.65 15.37 12 15.37C10.35 15.37 8.81 14.44 7.98 12.95L6.67 13.68Z" fill={ fill || 'currentColor' } />
        <path d="M9 11C9.55228 11 10 10.5523 10 10C10 9.44772 9.55228 9 9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11Z" fill={ fill || 'currentColor' } />
        <path d="M19.93 11C19.97 11.33 20 11.66 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C12.34 4 12.67 4.03 13 4.07V2.05C12.67 2.02 12.34 2 12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 11.66 21.98 11.33 21.95 11H19.93Z" fill={ fill || 'currentColor' } />
        <path d="M15 11C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9C14.4477 9 14 9.44772 14 10C14 10.5523 14.4477 11 15 11Z" fill={ fill || 'currentColor' } />
        <path d="M19.5 4.5V2H17.5V4.5H15V6.5H17.5V9H19.5V6.5H22V4.5H19.5Z" fill={ fill || 'currentColor' } />
    </svg>

);
