import { notification as antdNotification } from 'antd';
import React, { useState } from 'react';
import { Danger, Info, CircleCheck, Error, Close, Lightbulb, Spinner, CloseLarge } from '@nackle/origami-icons';
import { Button } from '../../exports';

const getIcon = ( type ) => {
    switch ( type ) {
        case 'info':
            return <Info className="ant-notification-info-icon ant-notification-icon" />;
        case 'success':
            return <CircleCheck className="ant-notification-success-icon ant-notification-icon" />;
        case 'warning':
            return <Danger className="ant-notification-warning-icon ant-notification-icon" />;
        case 'error':
            return <Error className="ant-notification-error-icon ant-notification-icon" />;
        case 'tip':
            return <Lightbulb className="ant-notification-tip-icon ant-notification-icon" />;
        case 'loading':
            return <Spinner className="ant-notification-loading-icon ant-notification-icon" />;
        default:
            return;
    }
};

export const notification = {
    // static methods
    open: function ( config ) {
        antdNotification.open( {
            className: config.size === 'large' ? 'ant-notification-large' : config.className,
            type: config.type === 'tip' || config.type === 'loading' ? 'info' : config.type, // antd doesn't have a tip or loading type, overwrite what we pass to it but use custom icon
            icon: getIcon( config.type ),
            duration: config.duration,
            message: <>
                <div>{ config.message }</div>
            </>,
            closeIcon: <Button type='text' shape='circle' icon={ config.size === 'large' ? <CloseLarge/> : <Close/> }></Button>,
            description: config.description,
            ...config
        } );
    },
    info: function ( message, description, duration, onClose ) {
        this.open( {
            message,
            description,
            duration,
            onClose,
            type: 'info'
        } );
    },
    success: function ( message, description, duration, onClose ) {
        this.open( {
            message,
            description,
            duration,
            onClose,
            type: 'success'
        } );
    },
    warning: function ( message, description, duration, onClose ) {
        this.open( {
            message,
            description,
            duration,
            onClose,
            type: 'warning'
        } );
    },
    error: function ( message, description, duration, onClose ) {
        this.open( {
            message,
            description,
            duration,
            onClose,
            type: 'error'
        } );
    },
    tip: function ( message, description, duration, onClose ) {
        this.open( {
            message,
            description,
            duration,
            onClose,
            type: 'tip'
        } );
    },
    loading: function ( message, description, duration, onClose ) {
        this.open( {
            message,
            description,
            duration,
            onClose,
            type: 'loading'
        } );
    },
    // Callback methods
    useNotification: () => {
        const [ notificationApi, contextHolder ] = antdNotification.useNotification();

        const open = ( config ) => {
            notificationApi.open( {
                className: config.size === 'large' ? 'ant-notification-large' : config.className,
                type: config.type === 'tip' ? 'info' : config.type, // antd doesn't have a tip type, overwrite what we pass to it but use custom icon
                icon: getIcon( config.type ),
                duration: config.duration,
                message: <>
                    <div>{ config.message }</div>
                </>,
                closeIcon: <Button type='text' shape='circle' icon={ config.size === 'large' ? <CloseLarge/> : <Close/> }></Button>,
                description: config.description,
                ...config
            } );
        };
        const info = ( message, description, duration, onClose ) => {
            open( {
                message,
                description,
                duration,
                onClose,
                type: 'info'
            } );
        };
        const success = ( message, description, duration, onClose ) => {
            open( {
                message,
                description,
                duration,
                onClose,
                type: 'success'
            } );
        };
        const warning = ( message, description, duration, onClose ) => {
            open( {
                message,
                description,
                duration,
                onClose,
                type: 'warning'
            } );
        };
        const error = ( message, description, duration, onClose ) => {
            open( {
                message,
                description,
                duration,
                onClose,
                type: 'error'
            } );
        };
        const tip = ( message, description, duration, onClose ) => {
            open( {
                message,
                description,
                duration,
                onClose,
                type: 'tip'
            } );
        };
        const loading = ( message, description, duration, onClose ) => {
            open( {
                message,
                description,
                duration,
                onClose,
                type: 'loading'
            } );
        };
        // we need to insure that the methods keep the same reference between rerenders so we set as state variable.
        // unnecessary rerenders and side effects can happen otherwise.  
        const [ api ] = useState( { open, info, success, warning, error, tip, loading } );

        return [ api, contextHolder ];
    }
};

