import React from 'react';
import PropTypes from 'prop-types';
import { Spin as AntSpin } from 'antd';
import classNames from 'classnames';
const Spin = ( { ...props } ) => {
    const { size, children } = props;
    const spinClass = ( size ) => {
        switch ( size ) {
            case 'small':
                return 'nackle-spin-small';
            case 'large':
                return 'nackle-spin-large';
            case 'x-large':
                return 'nackle-spin-extra-large';
            default:
                return 'nackle-spin-default';
        }
    };
    const nackleClass = spinClass( size );
    const spinClasses = classNames( {
        [ props.className ]: props.className ? true : false,
        [ nackleClass ]: true
    } );
    return (
        <AntSpin
            indicator={ <SpinIndicator className="primary-color" /> }
            { ...props }
            className={ spinClasses } >{ children }</AntSpin>
    );
};

Spin.propTypes = {
    /** size of spinner */
    size: PropTypes.oneOf( [ 'small', 'large', 'x-large', 'default' ] ),
    /** class name for spinner */
    className: PropTypes.string
};
export { Spin };

export const SpinIndicator = ( { className, fill, ...props } ) => (
    <svg width="50" height="50" className={ className ? `${ className } ant-spin-dot-spin` : 'ant-spin-dot-spin' } { ...props } viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.2" x="7.3822" y="7.30811" width="7.49782" height="0.540556" transform="rotate(45 7.3822 7.30811)" fill={ fill || 'currentColor' }/>
        <rect opacity="0.2" width="7.50037" height="0.537697" transform="matrix(0.707107 0.707106 -0.707107 0.707106 37.8483 36.943)" fill={ fill || 'currentColor' }/>
        <path d="M42.8029 42.5955C38.2725 47.1679 31.9887 50 25.0435 50C18.0982 50 11.8145 47.1679 7.28406 42.5955L12.5982 37.303C15.7705 40.5118 20.1748 42.5 25.0435 42.5C29.9121 42.5 34.3165 40.5118 37.4888 37.303L42.8029 42.5955Z" fill={ fill || 'currentColor' }/>
        <path d="M42.7113 7.3125C38.188 2.79417 31.942 0 25.0435 0C18.1449 0 11.899 2.79417 7.37565 7.3125L12.6898 12.605C15.855 9.45025 20.2215 7.5 25.0435 7.5C29.8655 7.5 34.2319 9.45025 37.3972 12.605L42.7113 7.3125Z" fill={ fill || 'currentColor' }/>
    </svg>

);