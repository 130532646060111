import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AntdModal } from 'antd';
import { CloseLarge, Info,  Danger, Error, CircleCheck } from '@nackle/origami-icons';
import { Button } from '../button/button';
const Title = ( props ) => {
    const { title, subTitle } = props;
    if ( !title && !subTitle ) {
        return <></>;
    }
    if ( !subTitle ) {
        return <>{ title }</>;
    }
    return (
        <>
            { title }
            <div className={ 'ant-modal-subtitle' }>
                { subTitle }
            </div>
        </>
    );
};

const Modal = ( { children, ...props } ) => {
    const { title, subTitle } = props;
    return (
        <AntdModal closeIcon={ <CloseLarge width={ 20 } height={ 20 } /> } { ...props } title={ <Title title={ title } subTitle={ subTitle } /> }>
            { children }
        </AntdModal>
    );
};

Modal.confirm = ( config ) => {
    const { title } = config;
    const modal =  AntdModal.confirm( {
        ...config,
        icon: null,
        centered: config.centered || true,
        title: (
            <div className='confirm-modal-header'>
                <div className='confirm-modal-title'>{ title }</div>
                <Button onClick={ ()=> modal.destroy() } className='confirm-modal-header-close' aria-label="Close" shape='circle' icon={ <CloseLarge/> } type='text'/>
            </div>
        )
    } );
    return modal;
};

Modal.info = ( config ) => {
    const { title } = config; 
    const modal = AntdModal.info( {
        ...config,
        icon: null,
        centered: config.centered || true,
        title: (
            <div className='confirm-modal-header'>
                <Info className='ant-modal-icon info'/> 
                <div className='confirm-modal-title'>{ title }</div>
                <Button onClick={ ()=> modal.destroy() } className='confirm-modal-header-close' aria-label="Close" shape='circle' icon={ <CloseLarge/> } type='text'/>
            </div>
        )
    } );
    return modal;
};

Modal.success = ( config ) => {
    const { title } = config;
    const modal = AntdModal.success( {
        ...config,
        icon: null,
        centered: config.centered || true,
        title: (
            <div className='confirm-modal-header'>
                <CircleCheck className='ant-modal-icon success'/> 
                <div className='confirm-modal-title'>{ title }</div>
                <Button onClick={ ()=> modal.destroy() } className='confirm-modal-header-close' aria-label="Close" shape='circle' icon={ <CloseLarge/> } type='text'/>
            </div>
        )
    } );
    return modal;
};

Modal.error = ( config ) => {
    const { title } = config;
    const modal = AntdModal.error( {
        ...config,
        icon: null,
        centered: config.centered || true,
        title: (
            <div className='confirm-modal-header'>
                <Error className='ant-modal-icon error'/> 
                <div className='confirm-modal-title'>{ title }</div>
                <Button onClick={ ()=> modal.destroy() } className='confirm-modal-header-close' aria-label="Close" shape='circle' icon={ <CloseLarge/> } type='text'/>
            </div>
        )
    } );
    return modal;
};

Modal.warning = ( config ) => {
    const { title } = config;
    const modal =  AntdModal.warning( {
        ...config,
        icon: null,
        centered: config.centered || true,
        title: (
            <div className='confirm-modal-header'>
                <Danger className='ant-modal-icon warning'/> 
                <div className='confirm-modal-title'>{ title }</div>
                <Button onClick={ ()=> modal.destroy() } className='confirm-modal-header-close' aria-label="Close" shape='circle' icon={ <CloseLarge/> } type='text'/>
            </div>
        )
    } );
    return modal;
};

Modal.propTypes = {
    /** modal title */
    title: PropTypes.node,
    /** modal subtitle */
    subTitle: PropTypes.node
};
export { Modal };