import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import customIcons from './custom-icons';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend( customParseFormat );
dayjs.extend( advancedFormat );
dayjs.extend( weekday );
dayjs.extend( localeData );
dayjs.extend( weekOfYear );
dayjs.extend( weekYear );
require( 'dayjs/locale/de' );
require( 'dayjs/locale/fr' );
require( 'dayjs/locale/fr-ca' );
require( 'dayjs/locale/nl' );
require( 'dayjs/locale/pt-br' );
require( 'dayjs/locale/th' );
require( 'dayjs/locale/ru' );
require( 'dayjs/locale/zh-cn' );
require( 'dayjs/locale/zh-tw' );
require( 'dayjs/locale/ja' );
require( 'dayjs/locale/es' );
require( 'dayjs/locale/es-mx' );
require( 'dayjs/locale/en-gb' );
require( 'dayjs/locale/pl' );
require( 'dayjs/locale/it' );
require( 'dayjs/locale/ko' );
require( 'dayjs/locale/tr' );
dayjs.extend( localizedFormat );

const {
    calendarIcon,
    clearIcon,
    exchangeIcon,
    nextIcon,
    prevIcon,
    superNextIcon,
    superPrevIcon
} = customIcons;

class DatePicker extends React.Component {

    render () {
        const currentDate = dayjs();
        const cellRender = ( current, info ) => {
            const { type } = info;
            switch ( type ) {
                case 'date': 
                    return info.originNode;
                case 'month':
                    if ( currentDate.month() === current.month() ) {
                        return <div className='current'>{ info.originNode }</div>;
                    } else { return <div>{ info.originNode }</div>;
                    }
                case 'year': 
                    if ( currentDate.year() === current.year() ) {
                        return <div className='current'>{ info.originNode }</div>;
                    } else { return info.originNode;
                    }
                default:
                    return info.originNode;
            }
        };

        return (
            <AntDatePicker
            suffixIcon={ calendarIcon }
            allowClear={ { clearIcon: clearIcon } }
            nextIcon={ nextIcon }
            prevIcon={ prevIcon }
            superNextIcon={ superNextIcon }
            superPrevIcon={ superPrevIcon }
            cellRender={ cellRender }
            { ...this.props }
        />
        );
    }
} 

class RangePicker extends React.Component {
    render() {
        const currentDate = dayjs();
        const cellRender = ( current, info ) => {
            const { type } = info;
            switch ( type ) {
                case 'date': 
                    return info.originNode;
                case 'month':
                    if ( currentDate.month() === current.month() & currentDate.year() === current.year() ) {
                        return <div className='current'>{ info.originNode }</div>;
                    } else {
                        return <div>{ info.originNode }</div>;
                    }
                case 'year': 
                    if ( currentDate.year() === current.year() ) {
                        return <div className='current'>{ info.originNode }</div>;
                    } else {
                        return info.originNode;
                    }
                default:
                    return info.originNode;
            }
        };
        return (
            <AntDatePicker.RangePicker
                suffixIcon={ calendarIcon }
                allowClear={ { clearIcon: clearIcon } }
                separator={ exchangeIcon }
                nextIcon={ nextIcon }
                prevIcon={ prevIcon }
                superNextIcon={ superNextIcon }
                superPrevIcon={ superPrevIcon }
                cellRender={ cellRender }
                { ...this.props }
            />
        );
    }
}

DatePicker.RangePicker = RangePicker;

export { DatePicker, dayjs };
