import React from 'react';
import PropTypes from 'prop-types';
import { getString } from '@nackle/intl-tools';
import { Result as AntResult } from 'antd';
import { InfoFilled, CircleCheckFilled, ErrorFilled, CircleCloseFilled, DangerFilled } from '@nackle/origami-icons';
import { noReportsSelectedImageUrl, allCaughtUpImageUrl, failedLoadImageUrl, pageNotFound404ImageUrl } from '../resources';

//success | error | info | warning | 404 | 403 | 500
const getImage = ( status, title ) => {
    if ( status === 'success' ) {
        return <img src={ allCaughtUpImageUrl } alt={ getString( 'origami.result.success' ) } />;
    }
    if ( status === 'warning' || status === '500' || status === 'error' ) {
        return <img src={ failedLoadImageUrl } alt={ getString( 'origami.result.error' ) }  />;
    }
    if ( status === '404' || status === '403' ) {
        return <img src={ pageNotFound404ImageUrl } alt={ getString( 'origami.result.404' ) }  />;
    }
    return <img src={ noReportsSelectedImageUrl } alt={ getString( 'origami.result.default' ) }  />;
};

const getIcon = ( status ) => {
    if ( status === 'success' ) {
        return <CircleCheckFilled />;
    }
    // the figma matches the default icon for warning
    if ( status === 'warning' ) {
        return <DangerFilled />;
    }
    if ( status === 'error' || status === '500' ) {
        return <CircleCloseFilled />;
    }
    if ( status === '404' || status === '403' ) {
        return <ErrorFilled />;
    }
    return <InfoFilled />;
};

const Result = ( { children, ...props } ) => {
    let tempStatus = props.status;
    // antd won't let you overwrite the icon/image when it's one of these status's (?!?)
    // so we use the original status to get the correct icon/image
    // but overwrite the one we pass to the antd component
    if ( props.status === '404' || props.status === '403' || props.status === '500' ) {
        tempStatus = 'error';
    }
    return (
        <AntResult
            icon={ props.type === 'image' ? getImage( props.status, props.title ) : getIcon( props.status ) }
            { ...props }
            status={ tempStatus }
            className={ props.size === 'small' ? 'result-small' : '' }
        >
            { children }
        </AntResult>
    );
};

Result.propTypes = {
    /** which result status to display */
    status: PropTypes.oneOf( [ 'success', 'error', 'info', 'warning', '404', '403', '500' ] ),
    /** type of result */
    type: PropTypes.oneOf( [ 'image', 'icon' ] ),
    /** title of result, also alt text for image */
    title: PropTypes.string,
    /** size of result */
    size: PropTypes.oneOf( [ 'small', 'default' ] )
};
export { Result };

