import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as Avtr } from 'antd';

const AvatarGroup = ( { children, ...props } ) => {
    const { className, size } = props;
    switch ( size ) {
        case 'mini':
            return <Avtr.Group maxCount={ 4 } size={ 24 } className={ className ? `mini-avatar ${ className }` : 'mini-avatar' }  { ...props }>
                { children }
            </Avtr.Group>;
        case 'small':
            return <Avtr.Group maxCount={ 4 } size={ 36 } className={ className ? `small-avatar ${ className }` : 'small-avatar' } { ...props }>
                { children }
            </Avtr.Group>;
        case 'medium':
            return <Avtr.Group maxCount={ 4 } size={ 48 } className={ className ? `medium-avatar ${ className }` : 'medium-avatar' } { ...props }>
                { children }
            </Avtr.Group>;
        case 'large':
            return <Avtr.Group maxCount={ 4 } size={ 64 } className={ className ? `large-avatar ${ className }` : 'large-avatar' } { ...props }>
                { children }
            </Avtr.Group>;
        default:
            return <Avtr.Group maxCount={ 4 } size={ 48 } className={ className ? `medium-avatar ${ className }` : 'medium-avatar' } { ...props }>
                { children }
            </Avtr.Group>;
    }
};

AvatarGroup.propTypes = {
    /** Size of Avatar */
    size: PropTypes.oneOf( [ 'mini', 'small', 'medium', 'large' ] ),
    /** Class name */
    className: PropTypes.string,
    /** Children - generally initial(s) for fallback when no avatar image is present */
    children: PropTypes.node

};
AvatarGroup.defaultProps = {
    size: 'medium'
};
export  default AvatarGroup;


