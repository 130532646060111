import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getString, languageChangeEvent } from '../translations';

const reactStringReplace = require( 'react-string-replace' );

const propTypes = {
    code: PropTypes.string.isRequired,
    replaceOptions: PropTypes.object,
    className: PropTypes.string,
    TextComponent: PropTypes.any,
    uppercase: PropTypes.bool,
    escapeHTML: PropTypes.bool,
    stripHTML: PropTypes.bool,
    attributes: PropTypes.object
};

class LocaleString extends Component {
    componentDidMount() {
        languageChangeEvent.on( this.onLanguageChange );
    }

    componentWillUnmount() {
        languageChangeEvent.off( this.onLanguageChange );
    }

    stripSymbols = ( renderString ) => {
        const tag = /\$\{/g;
        const removeCurly = new RegExp( '}', 'g' );

        let returnString = renderString;
        returnString = returnString.replace( removeCurly, '' );
        returnString = returnString.replace( tag, '' );

        return returnString;
    };

    replaceWithReactComponent = ( templateString, o ) => {

        function replaceKeys( match, key ) {
            const component = o[ key ];
            const componentString = reactStringReplace( templateString, `$<${ key }>`, () => {
                return component;
            } );
            return componentString;
        }

        const strings = Object.keys( o ).map( ( key ) => {
            return replaceKeys( templateString, key );
        } ).flat();
        return strings;
    };

    onLanguageChange = () => this.forceUpdate();

    render() {
        const {
            code,
            escapeHTML = false,
            replaceOptions,
            stripHTML = false,
            TextComponent = 'span',
            uppercase = false,
            attributes,
            ...props
        } = this.props;

        //seperate out the regular replacements with the jsx replacements
        const stringReplaceOptions = {};
        const jsxReplaceOptions = {};

        for ( const property in replaceOptions ) {
            if ( typeof replaceOptions[ property ] === 'string' || typeof replaceOptions[ property ] === 'number' ) {
                stringReplaceOptions[ property ] = '' + replaceOptions[ property ];
            }
            else if ( React.isValidElement( replaceOptions[ property ] ) ) {
                jsxReplaceOptions[ property ] = replaceOptions[ property ];
            }
        }

        let translatedString = '';

        //do all the easy string replacements
        translatedString = getString( code, stringReplaceOptions, attributes );

        //do all the jsx replacements
        let translatedStrings = [];
        if ( Object.keys( jsxReplaceOptions ).length ) {
            translatedStrings = this.replaceWithReactComponent( translatedString, jsxReplaceOptions );
        }
        else {
            translatedStrings = [ translatedString ];
        }

        //and the rest
        let content = stripHTML ? translatedString.replace( /<(?:.|\n)*?>/gm, '' ) : translatedStrings;
        if ( uppercase ) {
            if ( Array.isArray( content ) ) {
                content = content.map( item => item.toUpperCase() );
            }
            else {
                content = content.toUpperCase();
            }
        }

        //give back the string
        return ( escapeHTML || Object.keys( jsxReplaceOptions ).length ) ?
            <TextComponent data-sl-anchor={ code } data-key={ code } style={ { 'pointerEvents': 'none' } } { ...props }>{ content }</TextComponent>
            :
            <TextComponent
                data-sl-anchor={ code }
                data-key={ code }
                dangerouslySetInnerHTML={ { __html: content } }
                style={ { 'pointerEvents': 'none' } }
                { ...props }
            />
            ;
    }
}

LocaleString.propTypes = propTypes;
export default LocaleString;
