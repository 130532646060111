import React from 'react';
import { Tooltip } from '../tooltip/tooltip.js';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Label = ( { className, icon, text, tooltipText, required, withInput, alignment, size, children } ) => {
    const labelClasses = classNames( {
        'nackle-label': true,
        [ alignment ]: alignment ? true : false,
        [ className ]: className ? true : false,
        [ size ]: size ? true : false,
        'default': size ? false : true
    } );
    if ( withInput === true ) {
        return (
            <label className={ labelClasses }>
                <div className="label-content-wrapper">
                    <div className="label-text">
                        { text }
                        {
                            required &&
                            <span className="required">*</span>
                        }
                    </div>
                    {
                        tooltipText && icon &&
                        <Tooltip title={ tooltipText }>
                            <div className="label-icon with-tooltip">{ icon }</div>
                        </Tooltip>
                    }
                    {
                        !tooltipText && icon &&
                        <div className="label-icon no-tooltip">{ icon }</div>
                    }
                </div>
                { children }
            </label>
        );
    } else {
        return (
            <div className={ labelClasses }>
                <div className="label-content-wrapper">
                    <div className="label-text">
                        { text }
                        {
                            required && 
                            <span className="required">*</span>
                        }
                    </div>
                    {
                        tooltipText && icon &&
                        <Tooltip title={ tooltipText }>
                            <div className="label-icon with-tooltip">{ icon }</div>
                        </Tooltip>
                    }
                    {
                        !tooltipText && icon &&
                        <div className="label-icon no-tooltip">{ icon }</div>
                    }
                </div>
                { children }
            </div>
        );
    }
};

Label.propTypes = {
    /** class name for component */
    className: PropTypes.string,
    /** icon for display on right side of label */
    icon: PropTypes.node,
    /** text/node to display */
    text: PropTypes.node,
    /** text for tooltip around icon */
    tooltipText: PropTypes.node,
    /** required? */
    required: PropTypes.bool,
    /** is this wrapping an input */
    withInput: PropTypes.bool,
    /** above or next to input */
    alignment: PropTypes.oneOf( [ 'horizontal', 'vertical' ] ),
    /** size of label */
    size: PropTypes.oneOf( [ 'small', 'default', 'large' ] )
};

export { Label };