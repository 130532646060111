import React from 'react';
import { Tag as Tg, Avatar } from 'antd';
import { CloseLarge, Close } from '@nackle/origami-icons';
const Tag = ( { children, ...props } ) => {
    const { size, closable } = props;
    switch ( size ) {
        case 'mini':
            return <Tg { ...props } closeIcon={ closable ? <CloseLarge width='8' height='8' fill='#59606D' /> : null } className={ closable ? `generic-tag-${ size } generic-close-tag-${ size }` : `generic-tag-${ size }` }> { children } </Tg>;
        case 'small':
            return <Tg { ...props } closeIcon={ closable ? <CloseLarge width='10' height='10' fill='#59606D' /> : null } className={ closable ? `generic-tag-${ size } generic-close-tag-${ size }` : `generic-tag-${ size }` }> { children } </Tg>;
        case 'medium':
            return <Tg { ...props } closeIcon={ closable ? <CloseLarge width='12' height='12' fill='#59606D' /> : null } className={ closable ? `generic-tag-${ size } generic-close-tag-${ size }` : `generic-tag-${ size }` }> { children } </Tg>;
        case 'large':
            return <Tg { ...props } closeIcon={ closable ? <CloseLarge width='14' height='14' fill='#59606D' /> : null } className={ closable ? `generic-tag-${ size } generic-close-tag-${ size }` : `generic-tag-${ size }` }> { children } </Tg>;
        default:
            return <Tg { ...props } closeIcon={ closable ? <CloseLarge width='12' height='12' fill='#59606D' /> : null } className={ closable ? `generic-tag-${ size } generic-close-tag-${ size }` : `generic-tag-${ size }` }> { children } </Tg>;
    }
};
Tag.Person = ( { children, ...props } ) => {
    const { src, shape, closable } = props;
    switch ( shape ) {
        case 'square':
            return <Tg { ...props }
                className={ closable ? 'square-tag-close' : 'square-tag' }
                icon={ <Avatar src={ src } shape='square' /> }
                closeIcon={ closable ? <Close width='16' height='16' fill='#59606D' /> : null }
            >
                { children }
            </Tg>;
        case 'round':
            return <Tg { ...props }
                className={ closable ? 'round-tag-close' : 'round-tag' }
                icon={ <Avatar src={ src } shape='circle' /> }
                closeIcon={ closable ? <Close width='16' height='16' fill='#59606D' /> : null }
            >
                { children }
            </Tg>;
        default:
            return <Tg { ...props }
                className={ closable ? 'square-tag-close' : 'square-tag' }
                icon={ <Avatar src={ src } shape='square' /> }
                closeIcon={ closable ? <Close width='16' height='16' fill='#59606D' /> : null }
            >
                { children }
            </Tg>;
    }
};
Tag.Color = ( { children, ...props } ) => {
    const { size, status } = props;
    switch ( status ) {
        case 'error':
            return <Tg { ...props }
                color='#DE1B1B'
                className={ `generic-tag-${ size } color-tag-${ size }` }
            >
                { children }
            </Tg>;
        case 'warning':
            return <Tg { ...props }
                color='#FCC419'
                className={ `generic-tag-${ size } warning-tag color-tag-${ size }` }
            >
                { children }
            </Tg>;
        case 'success':
            return <Tg { ...props }
                color='#038537'
                className={ `generic-tag-${ size } color-tag-${ size }` }
            >
                { children }
            </Tg>;
        case 'processing':
            return <Tg { ...props }
                color='#1D5BBF'
                className={ `generic-tag-${ size } color-tag-${ size }` }
            >
                { children }
            </Tg>;
        case 'purple':
            return <Tg { ...props }
                color='#7D3CBD'
                className={ `generic-tag-${ size } color-tag-${ size }` }
            >
                { children }
            </Tg>;
        case 'default':
            return <Tg { ...props }
                color='#59606D'
                className={ `generic-tag-${ size } color-tag-${ size }` }
            >
                { children }
            </Tg>;
        default:
            return <Tg { ...props }
                color='#59606D'
                className={ `generic-tag-${ size } color-tag-${ size }` }
            >
                { children }
            </Tg>;

    }
};
Tag.Lightcolor = ( { children, ...props } ) => {
    const { size, status } = props;
    switch ( status ) {
        case 'error':
            return <Tg { ...props }
                color='#FAF0F0'
                className={ `${ status } generic-tag-${ size }` }
            >
                { children }
            </Tg>;
        case 'warning':
            return status === 'warning' &&
                <Tg { ...props }
                    color='#FFF9DB'
                    className={ `${ status } generic-tag-${ size }` }
                >
                    { children }
                </Tg>;
        case 'success':
            return <Tg { ...props }
                color='#E1FAEB'
                className={ `${ status } generic-tag-${ size }` }
            >
                { children }
            </Tg>;
        case 'processing':
            return <Tg { ...props }
                color='#F0F5FC'
                className={ `${ status } generic-tag-${ size }` }
            >
                { children }
            </Tg>;
        case 'purple':
            return <Tg { ...props }
                color='#F5F0FA'
                className={ `${ status } generic-tag-${ size }` }
            >
                { children }
            </Tg>;
        default:
            return <Tg { ...props }
                color='#FAF0F0'
                className={ `${ status } generic-tag-${ size }` }
            >
                { children }
            </Tg>;
    }
};
export { Tag };

