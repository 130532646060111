import React from 'react';
import { Layout } from 'antd';
const { Header } = Layout;
import { Select as Nav } from '../select/select/select';
import { ChevronDown, Check } from '@nackle/origami-icons';

export const navOptions = [
    {
        label: 'Origami v2.0',
        value: 'https://nackle.biw-pages.com/paper/origami/'
    },
    {
        label: 'Mesabi',
        value: 'https://nackle.biw-pages.com/paper/mesabi/'
    },
    {
        label: 'Crane',
        value: 'https://nackle.biw-pages.com/paper/crane/'
    },
    {
        label: 'Origami Icons',
        value: 'https://nackle.biw-pages.com/paper/origami-icons/'
    },

];

const GlobalNav = ( { options, value, onChange, children, ...props } ) => {
    const handleGlobalNav = ( value ) => {
        window.location.href = value;
        if ( typeof onChange === 'function' ) {
            onChange( value );
        }
    };
    return (
        <Header className='global-nav-header'>
            <div className='global-nav'>

                <Nav
                    options={ options ? options : navOptions }
                    className='global-nav'
                    suffixIcon={ <ChevronDown /> }
                    menuItemSelectedIcon={ <Check /> }
                    defaultValue={ value || window.location.href }
                    onChange={ handleGlobalNav }
                    { ...props }
                >
                </Nav>

            </div>
            { children }
        </Header>
    );
};
export { GlobalNav };