import React, { Fragment, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input as AntInput } from 'antd';
import { Label } from '../../label/label.js';
import { NackleCaption } from '../../NackleCaption/nackle-caption.js';
import { Close } from '@nackle/origami-icons';
import Password from './password';
import Search from './search';


const Input = forwardRef( ( props, ref ) => {
    const { allowClear, status, statusIcon, suffix, label, labelProps, caption, captionProps, disabled, ...restProps } = props;

    const statusSuffix = (
        <Fragment>
            { suffix }
            { statusIcon }
        </Fragment>
    );

    return (
        label ?
            <Label { ...labelProps } withInput={ true } size={ restProps.size }>
                <div className="input-caption-wrapper">
                    <AntInput
                        ref={ ref }
                        status={ status }
                        suffix={ status ? statusSuffix : suffix }
                        allowClear={ allowClear ? { clearIcon: <Close /> } : false }
                        disabled={ disabled }
                        { ...restProps }
                    />
                    {
                        caption &&
                        <NackleCaption { ...captionProps } state={ status } disabled={ disabled }/>
                    }
                </div>
            </Label>
            :
            <div className="input-caption-wrapper">
                <AntInput
                    ref={ ref }
                    status={ status }
                    suffix={ status ? statusSuffix : suffix }
                    allowClear={ allowClear ? { clearIcon: <Close /> } : false }
                    disabled={ disabled }
                    { ...restProps }
                />
                {
                    caption &&
                    <NackleCaption { ...captionProps } size={ restProps.size } state={ status } disabled={ disabled } />
                }
            </div>
    );
} );

const TextArea = ( { label, labelProps, caption, captionProps, disabled, ...restProps } ) => (
    label ?
        <Label { ...labelProps } withInput={ true } size={ restProps.size }>
            <div className="input-caption-wrapper">
                <AntInput.TextArea { ...restProps } disabled={ disabled } />
                {
                    caption &&
                    <NackleCaption { ...captionProps } state={ restProps.status } disabled={ disabled } />
                }
            </div>
        </Label>
        :
        <div className="input-caption-wrapper">
            <AntInput.TextArea { ...restProps } disabled={ disabled }/>
            {
                caption &&
                <NackleCaption { ...captionProps } size={ restProps.size } state={ restProps.status } disabled={ disabled }/>
            }
        </div>

);
Input.propTypes = {
    /** show clear icon */
    allowClear: PropTypes.bool,
    /** optional status */
    status: PropTypes.oneOf( [ 'error', 'warning' ] ),
    /** optional status icon */
    statusIcon: PropTypes.node,
    /** icon to the right of input */
    suffix: PropTypes.node,
    /** has label? */
    label: PropTypes.bool,
    /** props for label */
    labelProps: PropTypes.object,
    /** has caption? */
    caption: PropTypes.bool,
    /** props for caption */
    captionProps: PropTypes.object,
    /** disabled state */
    disabled: PropTypes.bool
};
Input.Password = Password;
Input.Search = Search;

Input.TextArea = TextArea;

export { Input };