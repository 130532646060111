import qs from 'qs';
import routeMatcher from './route-matcher.js';
import './find-index-polyfill';

( function ( window ) {
    if ( !window.routeHistory ) {
        const documentTitle = document.title;

        const routeHistory = [];
        const middleware = [];
        const eventListeners = {};

        const historyPushState = history.pushState;
        const historyReplaceState = history.replaceState;
        const historyBack = history.back;
        const historyForward = history.forward;
        const historyGo = history.go;

        let ignorePopState = false;
        let nextRouteId = 1;
        let base = '';
        let findMatch = null;

        const updateTitle = ( title ) => {
            if ( title && documentTitle ) {
                document.title = `${ documentTitle } - ${ title }`;
            } else if ( title ) {
                document.title = title;
            } else if ( documentTitle ) {
                document.title = documentTitle;
            }
        };

        const removeEventListener = ( name, callback ) => {
            if ( typeof name === 'string' && typeof callback === 'function' ) {
                name = name.toLowerCase();

                if ( eventListeners[ name ] ) {
                    const index = eventListeners[ name ].findIndex( ( cb ) => cb === callback );

                    if ( index >= 0 ) {
                        eventListeners[ name ].splice( index, 1 );
                    }
                }
            }
        };

        const addEventListener = ( name, callback ) => {
            if ( typeof name === 'string' && typeof callback === 'function' ) {
                name = name.toLowerCase();

                if ( !eventListeners[ name ] ) {
                    eventListeners[ name ] = [];
                }

                eventListeners[ name ].push( callback );
            }

            return () => removeEventListener( name, callback );
        };

        const callEventListeners = ( name, args ) => {
            if ( typeof name === 'string' ) {
                name = name.toLowerCase();

                if ( eventListeners[ name ] ) {
                    eventListeners[ name ].forEach( ( eventListener ) => {
                        eventListener.apply( history, args );
                    } );
                }
            }
        };

        const getRouteId = ( state ) => {
            if ( state && state.__id !== undefined ) {
                return state.__id;
            } else {
                const routeId = nextRouteId++;
                history.replaceState( { __id: routeId } );
                return routeId;
            }
        };

        const applyMiddleware = ( items ) => {
            middleware.splice( 0, middleware.length );

            items.forEach( ( item ) => {
                middleware.push( item );
            } );
        };

        const runMiddleware = ( nextRoute, prevRoute ) => {
            return new Promise( ( resolve, reject ) => {
                const run = ( index, runState ) => {
                    if ( index < middleware.length ) {
                        middleware[ index ]( {
                            ok: ( state ) => {
                                run( index + 1, Object.assign( {}, runState, state ) );
                            },
                            cancel: ( state ) => {
                                resolve( { ok: false, state } );
                            },
                            redirect: ( pathname, state ) => {
                                resolve( { ok: false, redirect: pathname, state } );
                            }
                        }, nextRoute, prevRoute );
                    } else {
                        resolve( { ok: true, state: runState } );
                    }
                };

                run( 0, {} );
            } );
        };

        let isChanging = false;
        let activeRoute = { id: 0, pathname: '', state: {} };

        const refresh = ( force = false ) => {
            const pathname = base ? location.pathname.substring( base.length ) : location.pathname;
            const search = location.search;
            const hash = location.hash;

            return new Promise( ( resolve, reject ) => {
                setTimeout( () => {
                    if ( !isChanging ) {
                        isChanging = true;
                        callEventListeners( 'changing' );
                    }

                    let match = { item: null, params: {} };
                    if ( typeof findMatch === 'function' ) {
                        match = findMatch( pathname );
                    }

                    const prevRoute = activeRoute;
                    const nextRoute = {
                        id: getRouteId( history.state ),
                        pathname: pathname,
                        search: search,
                        query: qs.parse( search.substr( 1 ) ),
                        hash: hash.replace( '#', '' ),
                        match: match ? match.item : null,
                        state: Object.assign( {}, history.state, { params: match ? match.params : null } )
                    };

                    runMiddleware( nextRoute, prevRoute ).then( ( result ) => {
                        if ( result.ok ) {
                            isChanging = false;
                            activeRoute = nextRoute;

                            activeRoute.state = Object.assign( {},
                                activeRoute.state,
                                result.state
                            );

                            updateTitle( activeRoute.match ? activeRoute.match.title : activeRoute.title );

                            let action = force ? 'refresh' : 'none';
                            if ( nextRoute.id > prevRoute.id ) {
                                action = 'forward';
                            } else if ( nextRoute.id < prevRoute.id ) {
                                action = 'back';
                            } else if ( nextRoute.pathname !== prevRoute.pathname ) {
                                action = 'back';
                            }

                            resolve( { action, nextRoute, prevRoute } );

                            callEventListeners( 'changed', [ action, nextRoute, prevRoute ] );
                        } else {
                            if ( result.redirect ) {
                                const redirectState = Object.assign( {}, history.state, result.state );

                                history.replaceState( redirectState, null, result.redirect ).then( ( redirectResult ) => {
                                    resolve( redirectResult );
                                } );
                            } else if ( nextRoute.id < prevRoute.id ) {
                                ignorePopState = true;
                                historyForward.call( history );
                                setTimeout( () => {
                                    ignorePopState = false;
                                    resolve( { action: 'refresh', nextRoute, prevRoute } );
                                }, 10 );
                            } else if ( routeHistory.length > 1 ) {
                                ignorePopState = true;
                                historyBack.call( history );
                                setTimeout( () => {
                                    ignorePopState = false;
                                    resolve( { action: 'refresh', nextRoute, prevRoute } );
                                }, 10 );
                            } else {
                                history.replaceState( history.state, null, '/' ).then( ( backResult ) => {
                                    resolve( backResult );
                                } );
                            }
                        }
                    } );

                }, 0 );
            } );
        };

        const applyBase = ( pathname ) => {
            base = pathname;
            refresh();
        };

        const applyRoutes = ( items ) => {
            findMatch = routeMatcher( items );
        };

        const addRouteToHistory = ( id, pathname ) => {
            const index = routeHistory.findIndex( ( item ) => item.id === id );

            if ( index >= 0 ) {
                routeHistory.splice( index, routeHistory.length - index + 1 );
            }

            routeHistory.push( {
                id: id,
                pathname: pathname
            } );
        };

        const reset = () => {
            nextRouteId = 1;
            routeHistory.splice( 0, routeHistory.length );
        };

        const pushState = ( data, title = '', url = '' ) => {
            const id = nextRouteId++;

            if ( base && url ) {
                url = `${ base }${ url }`;
            }

            data = Object.assign( {}, data, { __id: id } );
            historyPushState.call( history, data, title || null, url || null );

            addRouteToHistory( id, url );

            return refresh();
        };
        history.pushState = pushState;

        const replaceState = ( data, title = '', url = '' ) => {
            const id = nextRouteId++;

            if ( base && url ) {
                url = `${ base }${ url }`;
            }

            const oldUrl = window.location.pathname;
            const newUrl = url.split( '?' )[ 0 ];

            data = Object.assign( {}, data, { __id: id } );
            historyReplaceState.call( history, data, title || null, url || null );

            addRouteToHistory( id, url );
            if ( oldUrl !== newUrl ) {
                return refresh();
            } else {
                return Promise.resolve();
            }
        };
        history.replaceState = replaceState;

        const back = () => {
            historyBack.call( history );

            return refresh();
        };
        history.back = back;


        const forward = () => {
            historyForward.call( history );

            return refresh();
        };
        history.forward = forward;

        const go = ( delta ) => {
            historyGo.call( history, delta );

            return refresh();
        };
        history.go = go;

        window.onpopstate = ( event ) => {
            if ( !ignorePopState ) {
                nextRouteId = getRouteId( event.state ) + 1;
                refresh();
            }
        };

        window.routeHistory = {
            push: ( pathname, state ) => {
                return pushState( state, null, pathname );
            },
            replace: ( pathname, state ) => {
                return replaceState( state, null, pathname );
            },
            back: () => {
                return back();
            },
            forward: () => {
                return forward();
            },
            go: ( delta ) => {
                return go( delta );
            },
            list: () => {
                return [ ...routeHistory ];
            },
            base: () => base,
            reset,
            refresh,
            applyBase,
            applyRoutes,
            applyMiddleware,
            addEventListener,
            removeEventListener
        };

        // replace the current history state
        history.replaceState( { __id: nextRouteId++ } );
    }
} )( window || global );

export default window.routeHistory;