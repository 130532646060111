import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Password from '../forms/input/password';
import { Popover } from '../popover/popover';
import {
    CheckCircleFilled
} from '@ant-design/icons';
import { LocaleString } from '@nackle/intl-tools';

class NacklePasswordHelper extends React.Component {
    constructor( props ) {
        super( props );
        this.pwordRef = React.createRef();

        this.state = {
            focused: false,
            allCriteriaMet: false,
            comlexityCriteriaMet: false,
            validation: {}
        };
    }
    static getDerivedStateFromProps( props, state ) {

        const {
            value = '',
            meetsAllCriteria,
            passwordRules,
            passwordRules: { complexityRequirement }
        } = props;

        const {
            allCriteriaMet: prevCriteriaMet,
            currentPwdLength: prevPasswordLength
        } = state;

        const complexity = passwordRules?.complexity || [];
        const currentPwdLength = value.length;

        if ( prevCriteriaMet && prevPasswordLength === currentPwdLength ) {
            return null;
        }

        const complexityTests = {
            isLowerCaseRequired: /^(?=.*[a-z]).+$/,
            isUpperCaseRequired: /^(?=.*[A-Z]).+$/,
            isSpecialCharRequired: /[-!$%#@^&*()_+|~=`{}[\]:";'<>?,./]/,
            isNumberRequired: /[0-9]/
        };

        const validation = {
            lengthValid: currentPwdLength >= passwordRules?.minLength,
            complexityValidation: {}
        };

        let complexityResults = [];
        let complexityCount = 0;
        let complexityCriteriaMet = false;
        let allCriteriaMet = false;

        complexity.forEach( rule => {
            validation.complexityValidation[ rule ] = complexityTests[ rule ].test( value );
        } );

        complexityResults = Object.values( validation.complexityValidation );

        complexityCount = complexityResults.reduce( ( count, valid ) => {
            return valid ? ++count : count;
        }, 0 );

        complexityCriteriaMet = complexityCount >= complexityRequirement;

        allCriteriaMet = validation.lengthValid && complexityCriteriaMet;

        if ( typeof meetsAllCriteria === 'function' ) {
            if ( allCriteriaMet || ( prevCriteriaMet && !allCriteriaMet ) ) {
                meetsAllCriteria( allCriteriaMet );
            }
        }

        return {
            validation,
            complexityCriteriaMet,
            allCriteriaMet,
            currentPwdLength
        };
    }

    handleFocus = event => {
        event && event.persist();
        const { onFocus } = this.props;

        this.setState( { focused: true } );

        if ( typeof onFocus === 'function' ) {
            onFocus( event );
        }
    };

    handleBlur = event => {
        event && event.persist();
        const { onBlur } = this.props;

        this.setState( { focused: false } );

        if ( typeof onBlur === 'function' ) {
            onBlur( event );
        }
    };

    render() {
        const {
            className,
            passwordRules = {},
            passwordRulesLabels = {},
            value = '',
            name,
            label,
            helpComponent,
            meetsAllCriteria,
            placement,
            ...props
        } = this.props;

        const complexityRules = passwordRules?.complexity || [];
        const complexityLabels = passwordRulesLabels?.complexity || {};

        const {
            focused,
            complexityCriteriaMet,
            validation: { complexityValidation, lengthValid }
        } = this.state;
        const poverContainer = document.getElementById( 'password-input' );
        const poverWidth = poverContainer?.offsetWidth;
        const classes = classNames(
            'nkl-password-helper',
            'nkl-text-input',
            { 'is-focused': focused },
            className
        );

        const minLengthClass = lengthValid
            ? 'nkl-password-helper--passes'
            : 'nkl-password-helper--fails';

        const complexityClass = complexityCriteriaMet
            ? 'nkl-password-helper--passes'
            : 'nkl-password-helper--fails';

        return (
            <div className={ classes } style={ { 'width': props.width } }>

                {
                    helpComponent
                    && <span className={ 'nkl-input-helper' }>{ helpComponent }</span>
                }

                {
                    label
                    && <label htmlFor={ name }>{ label }</label>
                }
                <div
                    className={ 'nkl-password-helper-inner' }

                    id={ 'password-input' }
                    ref={ this.pwordRef }>
                    <Popover open={ focused }
                        overlayStyle={ { 'width': props.width || poverWidth } }
                        placement={ placement || 'top' }
                        getPopupContainer={ () => this.pwordRef.current }
                        content={
                            <div className={ 'nkl-password-helper-rules' } aria-label="password requirements" >
                                <p className='all-passwords top'><LocaleString code="origami.passwordHelper.instructions" /></p>
                                <p className='all-passwords'><LocaleString code="origami.passwordHelper.instructionsAll" /></p>
                                <ul>
                                    <li className={ minLengthClass }>
                                        <CheckCircleFilled className={ 'nkl-icon' } />
                                        { ' ' }
                                        { passwordRulesLabels?.minLength }
                                    </li>
                                    <li className={ complexityClass }>
                                        <CheckCircleFilled className={ 'nkl-icon' } />
                                        { ' ' }
                                        { passwordRulesLabels?.complexityRequirement }
                                        <ul>
                                            { complexityRules.map( rule => {
                                                const classes = complexityValidation[ rule ]
                                                    ? 'nkl-password-helper--passes'
                                                    : 'nkl-password-helper--fails';

                                                return <li key={ rule } className={ classes } >
                                                    <CheckCircleFilled className={ 'nkl-icon' } />
                                                    { ' ' }
                                                    { complexityLabels[ rule ] }
                                                </li>;
                                            } ) }
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        }>


                        <Password
                            name={ name }
                            value={ value }
                            onChange={ ( thing ) => props.onChange( thing ) }
                            { ...props }
                            onBlur={ this.handleBlur }
                            onFocus={ this.handleFocus }
                        />
                    </Popover>
                </div>
            </div>
        );
    }
}

NacklePasswordHelper.propTypes = {
    /** optional class name */
    className: PropTypes.string,
    /** object of password rules */
    passwordRules: PropTypes.object,
    /** object of labels for rules */
    passwordRulesLabels: PropTypes.object,
    /** password input value */
    value: PropTypes.string,
    /** password input name */
    name: PropTypes.string,
    /** password input label */
    label: PropTypes.string,
    /** input helper component */
    helpComponent: PropTypes.node,
    /** callback when all criteria have been met */
    meetsAllCriteria: PropTypes.func,
    /** onFocus callback  */
    onFocus: PropTypes.func,
    /** onBlur callback */
    onBlur: PropTypes.func
};
export { NacklePasswordHelper };