import React, { forwardRef } from 'react';
import { Form as AntForm } from 'antd';
import { Item } from './item';
import classNames from 'classnames';


const Form = forwardRef( ( props, ref ) => {
    const { children, disabled, className, ...restProps } = props;
    const classes = classNames( {
        disabled: disabled ? true : false,
        [ className ]: className ? true : false
    } );
    return (
        <AntForm className={ classes } ref={ ref } { ...restProps } disabled={ disabled } >{ children }</AntForm> );
} );

Form.Item = Item;

Form.Item.useStatus = AntForm.Item.useStatus;

Form.useForm = AntForm.useForm;
Form.List = AntForm.List;
Form.ErrorList = AntForm.ErrorList;
Form.useFormInstance = AntForm.useFormInstance;
Form.useWatch = AntForm.useWatch;
Form.Provider = AntForm.Provider;

export { Form };