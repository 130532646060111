import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Carousel as Carousl, Card } from 'antd';
import { routeHistory } from '@nackle/biw-web-router';
import { NackleVideoPlayer } from '../NackleVideo/nackle-video-player';
import { ArrowLeft, ArrowRight } from '@nackle/origami-icons';

const Carousel = ( { effect, autoplay, autoplaySpeed, speed, dots, hasScrim, countPath, handleNavigate, locale, ...props } ) => {
    const [ currentIndex, setCurrentIndex ] = useState( 1 );
    const [ isModalOpen, setIsModalOpen ] = useState( false );
    const { newsItems, className } = props;
    const slider = useRef();

    const handleCancel = () => {
        setIsModalOpen( false );
      };
      const showModal = () => { 
        setIsModalOpen( true );
       };

    const updateCurrent = ( current, next ) => {
        setCurrentIndex( next + 1 );
    };

    const goNext = () => {
        slider.current.next();
    };

    const goPrev = () => {
        slider.current.prev();
    };
    const handleKeyPress = ( e, callback, variables ) => {
        if ( e.key === 'Enter' ) {
            if ( typeof callback === 'function' ) {
                if ( variables ) {
                    callback( variables );
                } else {
                    callback();
                }
            }
        }
    };

    const handleNavigation = ( e ) => {
        if ( typeof handleNavigate === 'function' ) {
            handleNavigate( event );
        } else if ( countPath ) {
            routeHistory.push( countPath );
        } else {
            return;
        }
    };
    const itemCount = newsItems.length;
    const navigate = ( { type, url } ) => {
        if ( type === 'EXTERNAL_URL' ) {
            window.open( url, '_blank' ).focus();
        } else if ( type === 'DOCUMENT' ) {
            window.open( url, '_blank' ).focus();
        } else if ( type === 'INTERNAL_URL' ) {
            routeHistory.push( url );
        } else if ( type === 'SCORM' ) {
            window.open( url, '_blank' ).focus();
        } 
    };
    return (
        <div className={ className ? `carousel ${ className }` : 'carousel' }>
            <Card bordered={ false } >
                { newsItems && newsItems.length > 0 &&
                    <Carousl
                        className="carousel"
                        beforeChange={ updateCurrent }
                        effect={ effect || 'fade' }
                        autoplay={ autoplay !== undefined ? autoplay : true }
                        autoplaySpeed={ autoplaySpeed || 7000 }
                        speed={ speed || 1000 }
                        dots={ dots !== undefined ? dots : false }
                        ref={ slider }
                        swipe={ false }
                        touchMove={ false }
                    >
                        { newsItems.map( ( item, index ) => {
                            let backgroundStyle = `url(${ item.imageUrl })`;
                            if ( hasScrim ) {
                                backgroundStyle = `linear-gradient(to bottom, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54)), url(${ item.imageUrl })`;
                            }
                            const sources = [];
                            if ( item.linkType === 'VIDEO' ) {
                                if ( item.videoUrl && item.videoUrl.length > 0 ) {
                                    item.videoUrl.map( video => {
                                        if ( video.descriptor !== 'thumbnail' ) {
                                            sources.push( { src: video.url, type: `video/${ video.descriptor }` } );
                                        }
                                    } );
                                }
                            }
                            return (
                                <div key={ item.id }
                                    className="carousel-content-container">
                                    <div
                                        className="img-container"
                                        style={ { backgroundImage: backgroundStyle } }
                                        tabIndex={ currentIndex - 1 === index ? 0 : -1 }
                                        onKeyPress={ item.linkType === 'NO_LINK' ? null : () => handleKeyPress( event, navigate, { type: item.linkType, url: item.url } ) } />
                                    <div className={ item.linkType === 'NO_LINK' ? 'news-content-container no-link' : 'news-content-container' } onClick={ item.linkType === 'NO_LINK' ? null : item.linkType === 'INTERNAL_URL' ? () => navigate( { type: item.linkType, url: item.path } ) : () => navigate( { type: item.linkType, url: item.url } ) }>
                                    { item.linkType === 'VIDEO' ?
                                        
                                        <div className='video-modal'> 
                                        {
                                            <>
                                            <img 
                                                onClick={ showModal }
                                                src='https://api.biw.cloud/v1/images/play-button.svg'/> 
                                                  <NackleVideoPlayer
                                                    setIsModalOpen ={ setIsModalOpen }
                                                    handleCancel = { handleCancel }
                                                    isModalOpen = { isModalOpen }
                                                    sources={ sources }
                                            />   
                                             </>
                                        }
                                        </div>
                                        : null
                                    } 
                                        <div className="content-box">
                                            <div className="text-container">{ item.text }</div>
                                        </div>
                                    </div>
                                    <div className="bottom-container">
                                        <div>
                                            <div className="controls">
                                                <div className="nav-button-container">
                                                    <div><div role="button" tabIndex={ currentIndex - 1 === index ? 0 : -1 } className="direction-arrow" onKeyPress={ () => handleKeyPress( event, goPrev ) } onClick={ goPrev }><ArrowLeft fill="white" height={ 24 } width={ 24 } /></div></div>
                                                    <div><div role="button" tabIndex={ currentIndex - 1 === index ? 0 : -1 } className="direction-arrow" onKeyPress={ () => handleKeyPress( event, goNext ) } onClick={ goNext }><ArrowRight fill="white" height={ 24 } width={ 24 } /></div></div>
                                                </div>
                                                <div className={ countPath ? 'clickable count-container' : 'count-container' } onClick={ () => handleNavigation( event ) }>{ `${ currentIndex } / ${ itemCount }` }</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } )
                        }
                    </Carousl>
                }
            </Card>
        </div>
    );
};

Carousel.propTypes = {
    /** Transition effect */
    effect: PropTypes.oneOf( [ 'scrollx', 'fade' ] ),
    /** Should Carousel autoplay on load */
    autoplay: PropTypes.bool,
    /** How long between autoplay transitions */
    autoplaySpeed: PropTypes.number,
    /** Duration of transition effect */
    speed: PropTypes.number,
    /** Should it display a scrim over the background image */
    hasScrim: PropTypes.bool,
    /** Should it display navigation dots */
    dots: PropTypes.bool,
    /** internal link for navigation when click on slide count element */
    countPath: PropTypes.string,
    /** callback called when navigating */
    handleNavigate: PropTypes.func,
    /** array of carousel items */
    newsItems: PropTypes.arrayOf( PropTypes.shape( {
    /** UUID */
    id: PropTypes.string,
    /** Url of slide background image */
    imageUrl: PropTypes.string.isRequired,
    /** Type of link when clicking slide */
    linkType: PropTypes.oneOf( [ 'EXTERNAL_URL', 'DOCUMENT', 'INTERNAL_URL', 'SCORM', 'NO_LINK', 'VIDEO' ] ),
    /** url of link */
    url: PropTypes.string,
    /** optional text to display */
    text: PropTypes.node
      } ) ),
    /** class name */
    className: PropTypes.string
};

Carousel.defaultProps = {
    effect: 'fade',
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 1000,
    dots: false,
    hasScrim: false

};
export { Carousel };



