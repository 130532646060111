import React from 'react';
import { Tabs as Tbs } from 'antd';
import { MathPlus, Close } from '@nackle/origami-icons';

const Tabs = ( { children, ...props } ) => {
    const { size, tabPosition, onChange, type, items, className } = props;
    return (
        <Tbs
            className={ className }
            items={ items }
            onChange={ onChange }
            size={ size }
            tabPosition={ tabPosition }
            type={ type }
            { ...props }
        >
        </Tbs>
    );
};

Tabs.Icons = ( { children, ...props } ) => {
    const { size, tabPosition, items } = props;
    return (
        <Tbs
            { ...props }
            size={ size }
            tabPosition={ tabPosition }
            items={ items.map( ( item ) => {

                return (
                    {
                        ...item
                    }
                );
            } ) }
        />
    );
};

Tabs.Card = ( { children, ...props } ) => {
    const { size, items, onChange, activeKey, onEdit } = props;
    switch ( size ) {
        case 'small':
            return <Tbs
                addIcon={ <MathPlus /> }
                onChange={ onChange }
                size={ size }
                type='editable-card'
                activeKey={ activeKey }
                onEdit={ onEdit }
                items={ items.map( ( item ) => {
                    return (
                        {
                            label: item.label,
                            key: item.key,
                            disabled: item.disabled ? item.disabled : false,
                            children: item.children,
                            closeIcon: <Close fill='#59606D' width='16' height='16' />,
                            ...item
                        }
                    );
                } ) }
            >
            </Tbs>;
        case 'middle':
            return <Tbs
                addIcon={ <MathPlus /> }
                onChange={ onChange }
                size={ size }
                type='editable-card'
                activeKey={ activeKey }
                onEdit={ onEdit }
                items={ items.map( ( item ) => {
                    return (
                        {
                            label: item.label,
                            key: item.key,
                            disabled: item.disabled ? item.disabled : false,
                            children: item.children,
                            closeIcon: <Close fill='#59606D' width='20' height='20' />,
                            ...item
                        }
                    );
                } ) }
            >
            </Tbs>;
        case 'large':
            return <Tbs
                addIcon={ <MathPlus /> }
                onChange={ onChange }
                size={ size }
                type='editable-card'
                activeKey={ activeKey }
                onEdit={ onEdit }
                items={ items.map( ( item ) => {
                    return (
                        {
                            label: item.label,
                            key: item.key,
                            disabled: item.disabled ? item.disabled : false,
                            children: item.children,
                            closeIcon: <Close fill='#59606D' width='24' height='24' />,
                            ...item
                        }
                    );
                } ) }
            >
            </Tbs>;
        default:
            return <Tbs
                addIcon={ <MathPlus /> }
                onChange={ onChange }
                size={ size }
                type='editable-card'
                activeKey={ activeKey }
                onEdit={ onEdit }
                items={ items.map( ( item ) => {
                    return (
                        {
                            label: item.label,
                            key: item.key,
                            disabled: item.disabled ? item.disabled : false,
                            children: item.children,
                            closeIcon: <Close fill='#59606D' width='20' height='20' />,
                            ...item
                        }
                    );
                } ) }
            >
            </Tbs>;
    }
};

export { Tabs };