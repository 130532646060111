import React, { forwardRef, useState, useEffect } from 'react';
import { InputNumber as AntInputNumber } from 'antd';
import { MathPlus, MathMinus, ErrorFilled } from '@nackle/origami-icons';
import classNames from 'classnames';
import { Button } from '../../exports';

const InputNumber = forwardRef( ( props, ref ) => {
    
    const [ number, setNumber ] = useState( props.defaultValue || undefined );

    useEffect( () => {
        if ( props.min && number === undefined ) {
            setNumber( props.min );
        }
    }, [ props.min ] );

    const getSizeClassName = ( size ) => {
        if ( size  === 'large' ) {
            return 'origami-input-number-large';
        }
        if ( size === 'small' ) {
            return 'origami-input-number-small';
        } else {
            return '';
        }
    };

    const getErrorClassName = ( error, min, max, optional ) => {
        if ( number === 0 && optional === true ) {
            return false;
        }
        if ( number > max || number < min || error ) {
            return 'origami-input-number-error';
        }
    };  

    const sizeClassName = getSizeClassName( props.size );
    const errorClassName = getErrorClassName( props.error, props.min, props.max, props.optional );

    const inputClass = classNames( {
        'origami-input-number': true,
        'origami-input-number-disabled': props.disabled ? true : false,
        [ sizeClassName ]: true,
        'origami-input-number-error': errorClassName,
    } );

    const handleStep = ( button ) => {
        const step = props.step ? props.step : 1;
        let newValue = number;
        
        switch ( button ) {
            case 'up':
                newValue = parseInt( newValue ) + parseInt( step );
                setNumber( newValue );
                break;
            case 'down':
                newValue = parseInt( newValue ) - parseInt( step );
                setNumber( newValue );
                break;
        }

        if ( typeof props.onStep === 'function' ) {
            props.onStep( number, { type: button, offset: step } );
        }
    };

    const handleInputChange = ( e ) => {
        console.log( e );
        setNumber( e );
        if ( typeof props.onChange === 'function' ) {
            props.onChange( e );
        }
    };

    return (
        <div className={ inputClass }>
            <Button 
                className='subtract-btn'
                icon={ <MathMinus/> }
                onClick={ ( e ) => handleStep( 'down' ) }
                size={ props.size }
                disabled={ props.disabled }
            ></Button>

            <AntInputNumber
                onKeyUp={ props.onKeyUp }
                value={ number }
                onChange={ ( e ) => handleInputChange( e ) }
                changeOnBlur={ true }
                type={ 'number' }
                ref={ ref }
                controls={ false }
                disabled={ props.disabled }
                addonAfter={ ( props.error || ( number > props.max || number < props.min ) && !( number === 0 && props.optional === true ) ) && <ErrorFilled/> }
                placeholder={ 0 }
            />

            <Button 
                className='add-btn'
                icon={ <MathPlus/> }
                onClick={ ( e ) => handleStep( 'up' ) }
                size={ props.size }
                disabled={ props.disabled }
            ></Button>
        </div>
    );
} );

export { InputNumber };