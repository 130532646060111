import React, {  forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Form as AntForm } from 'antd';
import { Label } from '../../label/label.js';
import { NackleCaption } from '../../NackleCaption/nackle-caption.js';

const Item = forwardRef( ( props, ref ) => {
    const { label, labelProps, help, captionProps, children, required, validateStatus, optional, ...restProps } = props;
    return (
        <AntForm.Item
            ref={ ref }
            label={ label ? <Label text={ label } required={ required } optional={ optional } status={ validateStatus } { ...labelProps } /> : undefined }
            help={ help ? <NackleCaption text={ help } state={ validateStatus } { ...captionProps } /> : undefined }
            colon={ false }
            validateStatus={ validateStatus }
            { ...restProps }
        >{ children }</AntForm.Item> );
} );

Item.propTypes = {
    /** text for label */
    label: PropTypes.string,
    /** label props */
    labelProps: PropTypes.object,
    /** caption text */
    help: PropTypes.string,
    /** caption props */
    captionProps: PropTypes.object,
    /** required */
    required: PropTypes.bool,
    /** validation status */
    validateStatus: PropTypes.oneOf( [ 'success', 'warning', 'error', 'validating' ] )
};

export { Item };