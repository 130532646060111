import React from 'react';
import {
    ChevronDoubleLeft,
    ChevronDoubleRight,
    ChevronLeft,
    ChevronRight
} from '@nackle/origami-icons';
import { getString } from '@nackle/intl-tools';
import { List as AntList } from 'antd';
import { Button } from '../../exports';

const JumpNext = () => (
    <a className='ant-pagination-item-link'>
        <div className='ant-pagination-item-container'>
            <span
                role='img'
                aria-label='double-right'
                className='anticon anticon-double-right ant-pagination-item-link-icon'
            >
                <ChevronDoubleRight />
            </span>
            <span className='ant-pagination-item-ellipsis'>•••</span>
        </div>
    </a>
);

const JumpPrev = () => (
    <a className='ant-pagination-item-link'>
        <div className='ant-pagination-item-container'>
            <span
                role='img'
                aria-label='double-left'
                className='anticon anticon-double-left ant-pagination-item-link-icon'
            >
                <ChevronDoubleLeft />
            </span>
            <span className='ant-pagination-item-ellipsis'>•••</span>
        </div>
    </a>
);
const List = ( { pagination, ...props } ) => {  
    
        const itemRender = ( _, type, originalElement ) => {
            if ( type === 'prev' ) {
                return <Button
                    type='link'
                    shape='circle'
                    aria-label={ getString( 'origami.pagination.previous' ) }
                    className='ant-pagination-chevron'
                    icon={ <ChevronLeft /> }
                />;
            } else if ( type === 'next' ) {
                return <Button
                    type='link'
                    shape='circle'
                    aria-label={ getString( 'origami.pagination.next' ) }
                    className='ant-pagination-chevron'
                    icon={ <ChevronRight /> }
                />;
            } else if ( type === 'jump-prev' ) {
                return <JumpPrev />;
            } else if ( type === 'jump-next' ) {
                return <JumpNext />;
            } else {
                return originalElement;
            }
        };
        if ( pagination ) {
            return (
                <AntList
                    pagination={ { itemRender: itemRender, align: 'center', ...pagination } }
                    { ...props }
                    >
                </AntList>
            );
        } else {
            return (
                <AntList
                    pagination={ false }
                    { ...props }
                    >
                        </AntList>
            );
                
        }
};
List.Item = AntList.Item;
List.Item.Meta = AntList.Item.Meta;

export { List };