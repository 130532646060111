import React from 'react';
import { Label } from '../../label/label';
import { NackleCaption } from '../../NackleCaption/nackle-caption';
import { Input as AntInput } from 'antd';
import { Eye, EyeClosed } from '@nackle/origami-icons';


const Password = ( { label, labelProps, caption, captionProps, disabled, ...restProps } ) => {
    const passwordIcon = ( visible ) => (
        <span
            role="img"
            aria-label="eye-invisible"
            tabIndex="-1"
        >
            { visible ? <EyeClosed /> : <Eye /> }
        </span>
    );

    return (
        label ?
            <Label { ...labelProps } withInput={ true } size={ restProps.size }>
                <div className="input-caption-wrapper">

                <AntInput.Password
                    iconRender={ passwordIcon }
                    { ...restProps }
                    disabled={ disabled }
                />
                {
                    caption &&
                    <NackleCaption { ...captionProps } state={ restProps.status } disabled={ disabled }/>
                }
                </div>
            </Label>
            :
            <div className="input-caption-wrapper">
                <AntInput.Password
                    iconRender={ passwordIcon }
                    { ...restProps }
                    disabled={ disabled }
                />
                {
                    caption &&
                    <NackleCaption { ...captionProps } state={ restProps.status } disabled={ disabled } />
                }
            </div>
    );
};

export default Password;
