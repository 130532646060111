import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const NackleCaption = ( { className, text, state, size, disabled } ) => {
    const captionClasses = classNames( {
        'nackle-caption': true,
        [ state ]: state ? true : false,
        [ className ]: className ? true : false,
        [ size ]: size ? true : false,
        'default': size ? false : true,
        disabled: disabled ? true : false
    } );
   return (
    <div className={ captionClasses }>
        { text }
    </div>
   );
};

NackleCaption.propTypes = {
    /** class name for component */
    className: PropTypes.string,
    /** text/node to display */
    text: PropTypes.node,
    /** error state or default */
    state: PropTypes.oneOf( [ 'error', 'default' ] ),
    /** size of label */
    size: PropTypes.oneOf( [ 'small', 'default', 'large' ] ),
    /** disabled state */
    disabled: PropTypes.bool
};

export { NackleCaption };