import React from 'react';
import PropTypes from 'prop-types';
import { Radio as AntRadio } from 'antd';
import Button from './button';
import Card from './card';
import Group from './group';


const Radio = ( { ...props } ) => {

    const statusClass = props.status ? `origami-radio-${ props.status }` : '';
    const sizeClass = props.size ? props.size : 'small';

    return (
        <AntRadio
            className={ `${ statusClass } ${ sizeClass }` }
            { ...props }
        />
    );
};

Radio.Group = Group;
Radio.Button = Button;
Radio.Card = Card;

Radio.propTypes = {
    /** error status */
    status: PropTypes.oneOf( [ 'error', undefined ] )
};

export { Radio };