import axios, { AxiosRequestConfig } from 'axios';
import { createSelector } from 'reselect';
import { KONG_URL } from '../../util/apiConfig';
import { apiRootUrl } from '@nackle/game-loader-comm-api';
import { SessionAppState } from './types';

export const getAuthState = ({ session }: SessionAppState) => session.authState;
export const getPersonId = ({ session: { accessTokenPayload } }: SessionAppState) =>
    accessTokenPayload &&
        (accessTokenPayload.person_id || accessTokenPayload.external_id) ||
        '';

export const getCompanyId = ({ session }: SessionAppState) => session.accessTokenPayload && (session.accessTokenPayload.cmp) || '';
export const getSessionId = ({ session }: SessionAppState) => session.access_token;
export const getLocale = ({ session }: SessionAppState) => session.locale;
export const isAuthenticated = ({ session }: SessionAppState) => session.access_token !== undefined;

export const getAxios = createSelector(
    [getSessionId, getCompanyId],
    (accessToken: string | undefined, companyId: string) => {

        const client = axios.create({
            baseURL: `${window.arcadeEnvironment ? apiRootUrl(window.arcadeEnvironment) : KONG_URL}`,
        });

        client.interceptors.request.use((request) => {

            request.headers.authorization = `Bearer ${accessToken}`
            request.headers[ 'company-id' ] = `${companyId}`;

            return request;
        });

        return client;
    },
);

declare global {
    interface Window {
        arcadeEnvironment:string;
    }
}
